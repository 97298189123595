import { Button, Link } from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import logo from "../../assets/Images/logo.png";
import * as actionTypes from "../../store/actions";
import DrawerToggleButton from "../SideDrawer/DrawerToggleButton";
import classes from "./Toolbar.module.css";
import UserMenu from "./UserMenu/UserMenu";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import YouTubeIcon from "@material-ui/icons/YouTube";

class Toolbar extends Component {
  render() {
    let form;
    if (!this.props.user) form = null;
    else form = <UserMenu name={this.props.user.name} />;
    console.log(this.props.location.pathname);
    return (
      <div>
        <div
          className={
            this.props.appMenuClass === "top"
              ? classes.ToolbarTop
              : classes.Toolbar
          }
        >
          {/* <div className={classes.SocialIcons}>
            <FacebookIcon
              className={classes.Facebook}
              onClick={() =>
                window.open("https://www.facebook.com/mediabiro/", "_blank")
              }
            />
            <InstagramIcon
              className={classes.Instagram}
              onClick={() => window.open("https://www.instagram.com", "_blank")}
            />
            <YouTubeIcon
              className={classes.Youtube}
              onClick={() =>
                window.open(
                  "https://www.youtube.com/user/infobiromne",
                  "_blank"
                )
              }
            />
          </div> */}
          <DrawerToggleButton click={this.props.drawerClickHandler} />
          <img src={logo} alt="logo" className={classes.Logo} />
          <div className={classes.NavBar}>
            <Link
              onClick={() => window.scroll(0, 0)}
              exact
              href="/"
              className={
                this.props.location.pathname === "/"
                  ? classes.ListItemActive
                  : classes.ListItem
              }
            >
              Početna
            </Link>
            <span
              style={{
                marginRight: "0px",
                color: "red",
                fontSize: "25px",
                opacity: "0.3",
              }}
            >
              {" "}
              |
            </span>

            {this.props.user ? (
              <Link
                onClick={() => window.scroll(0, 0)}
                exact
                href="/region"
                className={
                  this.props.location.pathname === "/region"
                    ? classes.ListItemActive
                    : classes.ListItem
                }
              >
                {" "}
                Region
              </Link>
            ) : null}
            {this.props.user ? (
              <span
                style={{
                  marginRight: "0",
                  color: "red",
                  fontSize: "25px",
                  opacity: "0.3",
                }}
              >
                {" "}
                |
              </span>
            ) : null}

            <Link
              href="/events"
              onClick={() => window.scroll(0, 0)}
              className={
                this.props.location.pathname === "/events"
                  ? classes.ListItemActive
                  : classes.ListItem
              }
            >
              Najava događaja
            </Link>
            <span
              style={{
                marginRight: "0px",
                color: "red",
                fontSize: "25px",
                opacity: "0.3",
              }}
            >
              {" "}
              |
            </span>

            <Link
              href="/service"
              onClick={() => window.scroll(0, 0)}
              className={
                this.props.location.pathname === "/service"
                  ? classes.ListItemActive
                  : classes.ListItem
              }
            >
              Usluge
            </Link>
            <span
              style={{
                marginRight: 0,
                color: "red",
                fontSize: "25px",
                opacity: "0.3",
              }}
            >
              {" "}
              |
            </span>

            <Link
              href="/contact"
              onClick={() => window.scroll(0, 0)}
              className={
                this.props.location.pathname === "/contact"
                  ? classes.ListItemActive
                  : classes.ListItem
              }
            >
              Kontakt
            </Link>
          </div>

          {/* <span
            style={{
              marginLeft: "0px",
              color: "red",
              fontSize: "25px",
              opacity: "0.3",
            }}
          >
            {" "}
            |
          </span> */}

          {/* <Link
            href="/search"
            onClick={() => window.scroll(0, 0)}
            className={classes.ListItem}
          >
            Pretraga
            <span
              style={{
                marginLeft: "auto",
                color: "red",
                fontSize: "25px",
                opacity: "0.3",
              }}
            >
              {" "}
              |
            </span>
          </Link> */}

          {/* <Link href="/termsofuse" onClick={() => window.scroll(0, 0)} className={classes.ListItem}>
              Uslovi korišćenja
            </Link> */}

          {!this.props.user ? (
            <div className={classes.Buttons}>
              <Button
                className={classes.Button}
                btnType="Login"
                onClick={this.props.hadnleTogleLogin}
                startIcon={<AccountCircleIcon style={{ fontSize: 25 }} />}
              >
                Prijavi se
              </Button>
              <span
                style={{
                  marginRight: 0,
                  color: "red",
                  fontSize: "25px",
                  opacity: "0.3",
                }}
              >
                {" "}
                |
              </span>
              <Button
                className={classes.Button}
                btnType="Registration"
                onClick={this.props.hadnleTogleRegistration}
                startIcon={<ContactMailIcon style={{ fontSize: 25 }} />}
              >
                Registruj se
              </Button>
            </div>
          ) : null}
          <div className={classes.Temp}>{form}</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loginClicked: () => dispatch({ type: actionTypes.TOGGLE_LOGIN }),
    registartionClicked: () =>
      dispatch({ type: actionTypes.TOGGLE_REGISTRATION }),
    hadnleTogleLogin: () => dispatch({ type: actionTypes.TOGGLE_LOGIN }),
    hadnleTogleRegistration: () =>
      dispatch({ type: actionTypes.TOGGLE_REGISTRATION }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Toolbar));
