import React from "react";
import PropTypes from "prop-types";

const Message = props => {
  return (
    <div className={`alert alert-${props.type} alert-dismissible fade show`} role="alert" style={{margin: "0px"}}>
      {props.msg}
      <button
        type="button"
        className="close"
        data-dismiss="alert"
        aria-label="Close"
        onClick={props.clicked}
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  );
};

Message.propTypes = {
  msg: PropTypes.string.isRequired
};

export default Message;
