import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import jwt from "jsonwebtoken";

import axios from "../../utils/axios";
import classes from "./Login.module.css";
import Button from "../../components/UI/Button/Button";
import Input from "../../components/UI/Input/Input";
import * as actionTypes from "../../store/actions";
import Message from "../../components/UI/Message/Message";
import Logo from "../../assets/Images/MediBiro_png.png";

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMsg: "",
      hidden: true,
      email: "",
      password: ""
    };

    //Password
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.toggleShow = this.toggleShow.bind(this);
  }

  updateField(event, password) {
    let newState = { ...this.state };
    newState[password] = event.target.value;
    this.setState(newState);
  }

  closeMsgHandler = () => {
    this.setState({
      ...this.state,
      errorMsg: ""
    });
  };

  handleKeyPress = event => {
    if (event.key === "Enter") {
      this.submitHandler(this.state.email, this.state.password);
    }
  };

  async submitHandler(email, password) {
    if (!email || !password) {
      this.setState({
        ...this.state,
        errorMsg: "Molimo Vas popunite sva polja."
      });
      return;
    }
    axios
      .post("api/v1/users/login", {
        email,
        password
      })
      .then(res => {
        const token = res.data.token;
        const user = jwt.decode(res.data.token).user;
        localStorage.setItem("jwtToken", token);
        this.props.onLoginSubmited(user);
        this.props.history.push("/");
        window.location.reload(true);
      })
      .catch(err => {
        // const errorMsg = err.response.data.message;
        // const newState = { ...this.state };
        // newState.errorMsg = errorMsg;
        // newState.email = "";
        // newState.password = "";

        // this.setState(newState);
        this.setState({
          ...this.state,
          errorMsg: "E-mail ili/i password nisu tačni."
        });
      });
  }

  //password
  handlePasswordChange(e) {
    this.setState({ password: e.target.value });
  }

  toggleShow() {
    this.setState({ hidden: !this.state.hidden });
  }

  componentDidMount() {
    if (this.props.password) {
      this.setState({ password: this.props.password });
    }
  }

  render() {
    return (
      <div className={classes.Login}>
        <div className={classes.Logo}>
          <img src={Logo} alt="logo" className={classes.Logo} />
        </div>
        {this.state.errorMsg ? (
          <Message
            type={"danger"}
            msg={this.state.errorMsg}
            clicked={this.closeMsgHandler}
          />
        ) : null}
        <div className={classes.Email}>
          <Input
            placeholder={"Unesite email"}
            changed={event => this.updateField(event, "email")}
            textarea={true}
            value={this.state.email}
            onKeyPress={event => this.handleKeyPress(event)}
          />
        </div>

        <div className={classes.Password}>
          <Input
            placeholder={"Unesite lozinku"}
            elementType={"input"}
            inputType={"password"}
            value={this.state.password}
            changed={this.handlePasswordChange}
            onKeyPress={event => this.handleKeyPress(event)}
          />
        </div>
        {/* <p style={{ color: "red" }}>{this.state.errorMsg}</p> */}
        <div className={classes.Buttons}>
          <Button
            btnType="Submit"
            clicked={() =>
              this.submitHandler(this.state.email, this.state.password)
            }
            onKeyPress={event => this.handleKeyPress(event)}
          >
            Prijava
          </Button>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onLoginSubmited: data =>
      dispatch({ type: actionTypes.SET_USER, user: data })
  };
};

export default connect(null, mapDispatchToProps)(withRouter(Login));
