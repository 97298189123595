import React, { Component } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import axios from "../../utils/axios";
import classes from "./Register.module.css";
import Button from "../../components/UI/Button/Button";
import Input from "../../components/UI/Input/Input";
import Spiner from "../../components/UI/Spinner/Spinner";
import Logo from "../../assets/Images/MediBiro_png.png";

class Register extends Component {
  constructor(props) {
    super(props);

    this.state = {
      //Basic settings for registration
      registraionSuccessful: false,
      displaySpiner: false,
      hidden: true,
      //Registration fields
      fullname: "",
      email: "",
      password: "",
      country: "Srbija",
      //extraFields
      company: "",
      mobilePhone: "",
      editorsPhoneNumber: "",
      mainEditorFullName: "",
      mainEditorEmail: "",
      errorPassword: "",
      //checkboxes
      checked: false,
      isMediaProfile: false,
    };

    //Password
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.toggleShow = this.toggleShow.bind(this);
  }

  updateField(event, field) {
    let newState = { ...this.state };
    newState[field] = event.target.value;
    this.setState(newState);
  }

  toggleChange = (_, field) => {
    let newState = { ...this.state };
    newState[field] = !this.state[field];
    this.setState(newState);
  };

  componentWillUnmount = () => {
    this.setState((prevState) => {
      return {
        ...prevState,
        registraionSuccessful: false,
        displaySpiner: false,
        hidden: true,
        fullname: "",
        email: "",
        password: "",
        country: "Srbija",
        company: "",
        mobilePhone: "",
        editorsPhoneNumber: "",
        mainEditorFullName: "",
        mainEditorEmail: "",
        checked: false,
        isMediaProfile: false,
      };
    });
  };

  submitHandler(
    fullname,
    email,
    password,
    country,
    subscribed,
    isMediaProfile,
    company,
    mobilePhone,
    editorsPhoneNumber,
    mainEditorFullName,
    mainEditorEmail
  ) {
    this.setState({
      ...this.setState,
      displaySpiner: true,
    });
    axios
      .post("/api/v1/users/signup", {
        name: fullname,
        email,
        country,
        company,
        subscribed,
        password,
        mobilePhone,
        editorsPhoneNumber,
        mainEditorFullName,
        mainEditorEmail,
        isMediaProfile: true,
      })
      .then((res) => {
        let newState = { ...this.state };
        newState.registraionSuccessful = true;
        newState.displaySpiner = false;
        this.setState(newState);
      })
      .catch((err) => {
        // console.log(err);
      });
  }

  //password
  handlePasswordChange(e) {
    this.setState({ password: e.target.value });
  }
  handlePasswordConfirmChange(e) {
    this.setState({ passwordConfirm: e.target.value });
  }

  handleInputPasswordBlur(e) {
    const password = e.target.value;
    if (password.length < 8) {
      this.setState({
        ...this.state,
        errorPassword: "Ovo polje mora imati najmanje 8 karaktera.",
      });
    } else {
      this.setState({
        ...this.state,
        errorPassword: "",
      });
    }
  }

  toggleShow() {
    this.setState({ hidden: !this.state.hidden });
  }

  componentDidMount() {
    if (this.props.password) {
      this.setState({ password: this.props.password });
    }
  }

  render() {
    let renderingConntent, extrafields;
    if (true) {
      // if (this.state.isMediaProfile) {
      extrafields = (
        <div>
          <Input
            placeholder={"Unesite ime kompanije"}
            changed={(event) => this.updateField(event, "company")}
            textarea={true}
            value={this.state.company}
          />
          <Input
            placeholder={"Unesite broj vašeg mobilnog telefona"}
            changed={(event) => this.updateField(event, "mobilePhone")}
            textarea={true}
            value={this.state.mobilePhone}
          />
          <Input
            placeholder={"Unesite broj telefona redakcije"}
            changed={(event) => this.updateField(event, "editorsPhoneNumber")}
            textarea={true}
            value={this.state.editorsPhoneNumber}
          />
          <Input
            placeholder={"Unesite ime i prezime glavnog urednika"}
            changed={(event) => this.updateField(event, "mainEditorFullName")}
            textarea={true}
            value={this.state.mainEditorFullName}
          />
          <Input
            placeholder={"Unesite e-mail glavnog urednika"}
            changed={(event) => this.updateField(event, "mainEditorEmail")}
            textarea={true}
            value={this.state.mainEditorEmail}
          />
        </div>
      );
    }
    if (this.state.registraionSuccessful) {
      renderingConntent = (
        <div style={{ backgroundColor: "#f4f4f4" }}>
          <h4 style={{ color: "#ad0900" }}>Uspešno ste se registrovali</h4>
          <h6>Vaš zahtev za aktivaciju profila je proslđen administraciji.</h6>
          <h6>Molimo Vas, pričekajte. Hvala.</h6>
        </div>
      );
    } else {
      if (this.state.displaySpiner) {
        renderingConntent = <Spiner />;
      } else {
        renderingConntent = (
          <div className={classes.Register}>
            <div className={classes.Logo}>
              <img src={Logo} alt="logo" className={classes.Logo} />
            </div>

            {/* <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.isMediaProfile}
                  onChange={(event) =>
                    this.toggleChange(event, "isMediaProfile")
                  }
                  value="checkedF"
                  style={{
                    color: " #ad0900 ",
                  }}
                />
              }
              label="Da li se prijavljujete kao medij?"
            /> */}

            <Input
              placeholder={"Unesite ime i prezime"}
              changed={(event) => this.updateField(event, "fullname")}
              textarea={true}
              value={this.state.name}
            />

            <Input
              placeholder={"Unesite e-mail"}
              changed={(event) => this.updateField(event, "email")}
              textarea={true}
              value={this.state.email}
            />

            <Input
              placeholder={"Unesite lozinku"}
              changed={(event) => this.updateField(event, "password")}
              textarea={true}
              value={this.state.password}
              inputType="password"
              onBlur={this.handleInputPasswordBlur.bind(this)}
              elementType="input"
            />
            {this.state.errorPassword === "" ? null : (
              <p style={{ color: "red" }}>{this.state.errorPassword}</p>
            )}

            <Input
              label="Odabrati državu"
              changed={(event) => this.updateField(event, "country")}
              value={this.state.country}
              elementType={"select"}
              elementConfig={{
                options: [
                  { value: "Srbija", displayValue: "Srbija" },
                  { value: "Crna Gora", displayValue: "Crna Gora" },
                ],
              }}
            />
            {true ? extrafields : null}
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.checked}
                  onChange={(event) => this.toggleChange(event, "checked")}
                  value="checkedF"
                  style={{
                    color: " #ad0900 ",
                  }}
                />
              }
              label="Želim da primam obaveštenja"
            />

            <ul className={classes.Buttons}>
              <Button
                disabled={this.state.password.length < 8}
                btnType="Submit"
                clicked={() =>
                  this.submitHandler(
                    this.state.fullname,
                    this.state.email,
                    this.state.password,
                    this.state.country,
                    this.state.checked,
                    this.state.isMediaProfile,
                    this.state.company,
                    this.state.mobilePhone,
                    this.state.editorsPhoneNumber,
                    this.state.mainEditorFullName,
                    this.state.mainEditorEmail
                  )
                }
              >
                Registruj se
              </Button>
            </ul>
          </div>
        );
      }
    }
    return <div>{renderingConntent}</div>;
  }
}

export default Register;
