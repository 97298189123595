// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-components-SideDrawer-DrawerToggleButton-module__toggle_button--63ayp {\n  display: flex;\n  flex-direction: column;\n  justify-content: space-around;\n  height: 25px;\n  width: 15px;\n  background: transparent;\n  border: none;\n  cursor: pointer;\n  margin: 12px;\n  box-sizing: border-box;\n}\n\n.src-components-SideDrawer-DrawerToggleButton-module__toggle_button--63ayp:hover .src-components-SideDrawer-DrawerToggleButton-module__toggle_button__line--1OxHN {\n  background: rgba(173, 9, 0, 0.6);\n}\n\n.src-components-SideDrawer-DrawerToggleButton-module__toggle_button--63ayp:hover {\n  /* background-color: rgba(173, 9, 0, 0.2); */\n}\n\n.src-components-SideDrawer-DrawerToggleButton-module__toggle_button--63ayp:focus {\n  outline: none;\n}\n\n.src-components-SideDrawer-DrawerToggleButton-module__toggle_button__line--1OxHN {\n  width: 40px;\n  height: 2px;\n  background: black;\n  transition: all 0.3s;\n}\n\n@media (min-width: 1024px) {\n  .src-components-SideDrawer-DrawerToggleButton-module__toggle_button--63ayp {\n    display: none;\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"toggle_button": "src-components-SideDrawer-DrawerToggleButton-module__toggle_button--63ayp",
	"toggle_button__line": "src-components-SideDrawer-DrawerToggleButton-module__toggle_button__line--1OxHN"
};
module.exports = exports;
