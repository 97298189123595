import React, { Component } from "react";
import Loadable from "react-loadable";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import Toolbar from "../src/components/Toolbar/Toolbar";
import NoMatch from "./components/NoMatch/NoMatch";
// import Services from "./components/Services/Services";
import SideDrawer from "./components/SideDrawer/SideDrawer";
import Backdrop from "./components/UI/Backdrop/Backdrop";
import Modal from "./components/UI/Modal/Modal";
// import Home from "./containers/Home/Home";
import Login from "./containers/Login/Login";
// import Region from "./containers/Region/Region";
import Register from "./containers/Register/Register.js";
import SearchFields from "./containers/SearchFields/SearchFields";
import styles from "./Main.module.css";
import * as actionTypes from "./store/actions";

function Loading({ error }) {
  if (error) {
    return "Oh nooess!";
  } else {
    return <h3>Loading...</h3>;
  }
}

class App extends Component {
  state = {
    activeClass: "normal",
  };

  componentDidMount() {
    window.addEventListener("scroll", () => {
      let activeClass = "normal";
      if (window.scrollY > 70 && this.state.activeClass !== "top") {
        activeClass = "top";
        this.setState({ ...this.state, activeClass });
      } else if (window.scrollY <= 70 && this.state.activeClass === "top") {
        this.setState({ ...this.state, activeClass });
      }
    });
  }

  LoadHome = Loadable({
    loader: () => import("./containers/Home/Home"),
    loading: Loading,
  });
  LoadRegion = Loadable({
    loader: () => import("./containers/Region/Region"),
    loading: Loading,
  });
  LoadServices = Loadable({
    loader: () => import("./components/Services/Services"),
    loading: Loading,
  });
  LoadAdminPage = Loadable({
    loader: () => import("./containers/AdminPage/AdminPage"),
    loading: Loading,
  });
  LoadContactForm = Loadable({
    loader: () => import("./containers/ContactForm/ContactForm"),
    loading: Loading,
  });
  LoadUserSetting = Loadable({
    loader: () => import("./containers/UserSettings/UserSetting"),
    loading: Loading,
  });
  LoadSearch = Loadable({
    loader: () => import("./containers/Search/Search"),
    loading: Loading,
  });
  LoadPost = Loadable({
    loader: () => import("./containers/Post/Post"),
    loading: Loading,
  });
  LoadRelatedPosts = Loadable({
    loader: () => import("./containers/RelatedPosts/RelatedPosts"),
    loading: Loading,
  });
  LoadEvents = Loadable({
    loader: () => import("./containers/Events/Events"),
    loading: Loading,
  });

  TermsOfUse = Loadable({
    loader: () => import("./components/TermsOfUse/TermsOfUse"),
    loading: Loading,
  });

  Impressum = Loadable({
    loader: () => import("./components/Impressum/Impressum"),
    loading: Loading,
  });

  render() {
    const loginModal = (
      <Modal
        show={this.props.loginOpen}
        close={this.props.hadnleTogleLogin}
        size={400}
      >
        <Login />
      </Modal>
    );
    const registrationModal = (
      <Modal
        show={this.props.registrationOpen}
        close={this.props.hadnleTogleRegistration}
        size={400}
      >
        <Register />
      </Modal>
    );
    let sideDrawer = <SideDrawer show={this.props.sideDrawerOpen} />;
    let backdrop;

    if (this.props.sideDrawerOpen) {
      sideDrawer = (
        <SideDrawer
          show={this.props.sideDrawerOpen}
          loginClicked={this.props.hadnleTogleLogin}
          registrationClicked={this.props.hadnleTogleRegistration}
        />
      );
      backdrop = (
        <Backdrop
          show={this.props.sideDrawerOpen}
          click={this.props.drawerToggleClickHandler}
        />
      );
    }
    return (
      <div className={styles.All}>
        {loginModal}
        {this.props.registrationOpen ? registrationModal : null}
        <div>
          <div style={{ position: "Absolute", top: "25px", left: "0px" }}></div>
        </div>
        <Toolbar
          drawerClickHandler={this.props.drawerToggleClickHandler}
          loginClicked={this.props.hadnleTogleLogin}
          registartionClicked={this.props.hadnleTogleRegistration}
          loggedInUser={this.props.user}
          appMenuClass={this.state.activeClass}
        />
        {this.props.location.pathname === "/contact" ? null : <SearchFields />}
        {sideDrawer}
        {backdrop}
        <Switch>
          <Route exact path="/" component={this.LoadHome} />
          <Route path="/region" component={this.LoadRegion} />
          <Route path="/service" component={this.LoadServices} ex />
          <Route path="/admin-page" component={this.LoadAdminPage} ex />
          <Route path="/contact" component={this.LoadContactForm} />
          <Route path="/settings" component={this.LoadUserSetting} ex />
          <Route path="/search" component={this.LoadSearch} ex />
          <Route
            path="/post/:id"
            component={this.LoadPost}
            ex
            target="_blank"
          />
          <Route path="/related/:tag" component={this.LoadRelatedPosts} ex />
          <Route path="/events" component={this.LoadEvents} ex />
          <Route path="/termsofuse" component={this.TermsOfUse} ex />
          <Route path="/impressum" component={this.Impressum} ex />
          {/* <Route component={NoMatch} /> */}
        </Switch>
        {/* <Footer /> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    loginOpen: state.loginOpen,
    registrationOpen: state.registrationOpen,
    sideDrawerOpen: state.sideDrawerOpen,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    hadnleTogleLogin: () => dispatch({ type: actionTypes.TOGGLE_LOGIN }),
    hadnleTogleRegistration: () =>
      dispatch({ type: actionTypes.TOGGLE_REGISTRATION }),
    drawerToggleClickHandler: () =>
      dispatch({ type: actionTypes.TOGGLE_DRAWER }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
