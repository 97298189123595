import {
  Button,
  FormControl,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import SearchIcon from "@material-ui/icons/Search";
import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import YouTubeIcon from "@material-ui/icons/YouTube";
import myClasses from "./SearchFields.module.css";

const useStyles = makeStyles(() => ({
  root: {
    // marginTop: 100,
    border: "1px solid #ccc",
    width: "45%",
    marginLeft: "auto",
    marginRight: "10px",
    height: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 5,
    "@media(max-width: 999px)": {
      width: "90%",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  tField: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "40%",
    height: 50,
    paddingLeft: 20,
    "@media (max-width: 768px)": {
      width: "34%",
      fontSize: "0.6rem !important",
    },
  },
  selectField: {
    // paddingRight: 20,
    paddingLeft: 20,
    width: "23%",
    height: 50,
    "@media (max-width: 768px)": {
      width: "29%",
      paddingLeft: 5,
      fontSize: "0.3rem",
    },
  },
  selectCountry: {
    width: "29%",
    fontSize: "0.8rem",
    height: 50,
    "@media (max-width: 768px)": {
      width: "32%",
      paddingLeft: 5,
      fontSize: "0.3rem",
    },
  },
  button: {
    height: 50,
    width: "8%",
    borderLeft: "1px solid #ccc",
    backgroundColor: "#DEDFE2",
    borderRadius: 5,
    "@media (max-width: 768px)": {
      width: "5%",
    },
  },
  underline: {
    "&:before": {
      height: 0,
    },
  },
  icon: {
    display: "none",
  },
  test: {
    fontSize: "1rem !important",
    "@media (max-width: 768px)": {
      fontSize: "0.8rem !important",
    },
  },
  selectIcons: {
    fontSize: "1.5rem !important",
    "@media (max-width: 768px)": {
      fontSize: "0.8rem !important",
    },
  },
}));

const SearchFields = (props) => {
  const [state, setState] = useState({
    searchText: "",
    field: "",
    country: "",
  });

  const submitSearch = () => {
    console.log(state);

    props.history.push(
      `/search?search=${state.searchText}&country=${state.country}&field=${state.field}&currentPage=1`
    );
  };

  const onEnterKey = (e) => {
    if (e.key === "Enter") {
      submitSearch();
    }
  };

  const classes = useStyles();
  return (
    <div className={myClasses.Container}>
      <div className={classes.root}>
        <TextField
          onKeyDown={onEnterKey}
          value={state.searchText}
          onChange={(e) => setState({ ...state, searchText: e.target.value })}
          fullWidth={true}
          className={classes.tField}
          variant="standard"
          placeholder="Pronađi.."
          margin={"none"}
          InputProps={{
            disableUnderline: true,
            className: classes.test,
          }}
        />
        <span style={{ borderLeft: "1px solid #ccc", height: "65%" }}></span>
        <FormControl className={classes.selectField}>
          <Select
            disableUnderline
            classes={{ icon: classes.icon }}
            value={state.field}
            onChange={(e) => setState({ ...state, field: e.target.value })}
            //   onChange={handleChange}
            displayEmpty
            endAdornment={
              <KeyboardArrowDownIcon
                className={classes.selectIcons}
                style={{ color: "#767676" }}
              />
            }
            style={{
              width: "100%",
              height: 50,

              color: "#767676",
            }}
            inputProps={{
              "aria-label": "Without label",
              className: classes.test,
            }}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 300,
                  // maxWidth: "400px !important",
                  // minWidth: "400px !important",
                },
              },
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
          >
            <MenuItem value="" disabled>
              Oblast
            </MenuItem>
            <MenuItem value={"Politika"}>Politika</MenuItem>
            <MenuItem value={"Ekonomija"}>Ekonomija</MenuItem>
            <MenuItem value={"Poljoprivreda"}>Poljoprivreda</MenuItem>
            <MenuItem value={"Sport"}>Sport</MenuItem>
            <MenuItem value={"Društvo"}>Društvo</MenuItem>
            <MenuItem value={"Hronika"}>Hronika</MenuItem>
            <MenuItem value={"Kultura"}>Kultura</MenuItem>
          </Select>
        </FormControl>
        <span style={{ borderLeft: "1px solid #ccc", height: "65%" }}></span>
        <FormControl className={classes.selectCountry}>
          <Select
            disableUnderline
            value={state.country}
            onChange={(e) => setState({ ...state, country: e.target.value })}
            //   onChange={handleChange}
            displayEmpty
            startAdornment={
              <LocationOnOutlinedIcon
                className={classes.selectIcons}
                style={{ color: "#767676", marginRight: 10 }}
              />
            }
            classes={{ icon: classes.icon }}
            style={{
              width: "100%",
              height: 50,
              // paddingRight: 20,
              // paddingLeft: 20,
              color: "#767676",
            }}
            inputProps={{
              "aria-label": "Without label",
              className: classes.test,
            }}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 300,
                  // maxWidth: "400px !important",
                  // minWidth: "400px !important",
                },
              },
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
          >
            <MenuItem value="" disabled>
              Država
            </MenuItem>
            <MenuItem value={"Crna Gora"}>Crna Gora</MenuItem>
            <MenuItem value={"Srbija"}>Srbija</MenuItem>
          </Select>
          {/* <FormHelperText>Placeholder</FormHelperText> */}
        </FormControl>
        <Button onClick={submitSearch} className={classes.button}>
          <SearchIcon />
        </Button>
      </div>
      <div
        style={{
          marginRight: "10%",
          marginRight: "10%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <FacebookIcon
          className={myClasses.Facebook}
          onClick={() =>
            window.open("https://www.facebook.com/mediabiro/", "_blank")
          }
        />
        <InstagramIcon
          className={myClasses.Instagram}
          onClick={() => window.open("https://www.instagram.com", "_blank")}
        />
        <YouTubeIcon
          className={myClasses.Youtube}
          onClick={() =>
            window.open("https://www.youtube.com/user/infobiromne", "_blank")
          }
        />
      </div>
    </div>
  );
};

export default withRouter(SearchFields);
