import axios from "axios";

const instance = axios.create({
  // baseURL: "http://173.249.55.75:4000/",
  baseURL: "https://mediabiro.tv:4000/",
  //baseURL:'http://localhost:4000/'
  //  baseURL: "https://www.no-underit.tk:4000/"
});

instance.defaults.headers.common[
  "Authorization"
] = `Bearer ${localStorage.getItem("jwtToken")}`;

export default instance;
