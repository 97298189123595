import React from "react";
import { NavLink, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ContactMailIcon from "@material-ui/icons/ContactMail";

import classes from "./SideDrawer.module.css";
// import Button from "../UI/Button/Button";
import MediBiro_png from "../../assets/Images/logo.png";
import * as actionTypes from "../../store/actions";
import { Button } from "@material-ui/core";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import YouTubeIcon from "@material-ui/icons/YouTube";

const sideDrawer = (props) => {
  let drawerCssClass = classes.side_drawer;
  if (props.show) {
    drawerCssClass = [classes.side_drawer, classes.open].join(" ");
  }
  return (
    <nav className={drawerCssClass}>
      <div className={classes.Logo_div}>
        <img src={MediBiro_png} alt="logo" className={classes.Logo} />
      </div>
      {!props.user ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "20px auto",
          }}
        >
          <Button
            style={{ fontSize: "15px !important", color: "#ad0900" }}
            className={classes.Button}
            btnType="Login"
            onClick={() => {
              props.drawerToggleClickHandler();
              props.hadnleTogleLogin();
            }}
            startIcon={<AccountCircleIcon />}
          >
            Prijavi se
          </Button>
          <span
            style={{
              marginRight: 0,
              color: "red",
              fontSize: "15px",
              opacity: "0.3",
            }}
          >
            {" "}
            |
          </span>
          <Button
            style={{ fontSize: "15px !important", color: "#ad0900" }}
            className={classes.Button}
            btnType="Registration"
            onClick={() => {
              props.drawerToggleClickHandler();
              props.hadnleTogleRegistration();
            }}
            startIcon={<ContactMailIcon style={{ fontSize: 15 }} />}
          >
            Registruj se
          </Button>
        </div>
      ) : null}
      <ul className={classes.Menu}>
        <li>
          <NavLink
            onClick={() => props.drawerToggleClickHandler()}
            exact
            to="/"
            activeClassName={classes.ListItemActive}
          >
            Početna
          </NavLink>
        </li>
        {props.user ? (
          <li>
            <NavLink
              onClick={() => props.drawerToggleClickHandler()}
              exact
              to="/region"
              activeClassName={classes.ListItemActive}
            >
              Region
            </NavLink>
          </li>
        ) : null}
        <li>
          <NavLink
            onClick={() => props.drawerToggleClickHandler()}
            to="/events"
            activeClassName={classes.ListItemActive}
          >
            Najava događaja
          </NavLink>
        </li>
        <li>
          <NavLink
            onClick={() => props.drawerToggleClickHandler()}
            to="/service"
            activeClassName={classes.ListItemActive}
          >
            Usluge
          </NavLink>
        </li>
        <li>
          <NavLink
            onClick={() => props.drawerToggleClickHandler()}
            to="/contact"
            activeClassName={classes.ListItemActive}
          >
            Kontakt
          </NavLink>
        </li>
        <li>
          <div className={classes.SocialIcons}>
            <FacebookIcon
              className={classes.Facebook}
              onClick={() =>
                window.open("https://www.facebook.com/mediabiro/", "_blank")
              }
            />
            <InstagramIcon
              className={classes.Instagram}
              onClick={() => window.open("https://www.instagram.com", "_blank")}
            />
            <YouTubeIcon
              className={classes.Youtube}
              onClick={() =>
                window.open(
                  "https://www.youtube.com/user/infobiromne",
                  "_blank"
                )
              }
            />
          </div>
        </li>
      </ul>
    </nav>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    drawerToggleClickHandler: () =>
      dispatch({ type: actionTypes.TOGGLE_DRAWER }),
    loginClicked: () => dispatch({ type: actionTypes.TOGGLE_LOGIN }),
    registartionClicked: () =>
      dispatch({ type: actionTypes.TOGGLE_REGISTRATION }),
    hadnleTogleLogin: () => dispatch({ type: actionTypes.TOGGLE_LOGIN }),
    hadnleTogleRegistration: () =>
      dispatch({ type: actionTypes.TOGGLE_REGISTRATION }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(sideDrawer));
