// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "* {\n  margin: 0;\n  padding: 0;\n}\n.src-Main-module__All--1BcG6 {\n  min-width: 100%;\n  min-height: calc(100vh - 168px);\n  background-color: #f4f4f4;\n  margin: 0 auto;\n  padding: 0px;\n}\n\n.src-Main-module__Buttons--3FdT6 {\n  position: absolute;\n  top: 35px;\n  right: 25px;\n  text-align: right;\n  font-weight: 400;\n  font-size: 70%;\n}\n\n@media (max-width: 1000px) {\n  .src-Main-module__Buttons--3FdT6 {\n    display: none;\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"All": "src-Main-module__All--1BcG6",
	"Buttons": "src-Main-module__Buttons--3FdT6"
};
module.exports = exports;
