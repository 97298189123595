// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-components-UI-Backdrop-Backdrop-module__Backdrop--2cVmF {\n    position: fixed;\n    width: 100%;\n    height: 100%;\n    top: 0;\n    left: 0;\n    background: rgba(0, 0, 0, 0.3);\n    z-index: 500;\n    text-align: right;\n    min-width: 100px;\n    padding-top: 100px;\n}\n\n.src-components-UI-Backdrop-Backdrop-module__Close--8wpjp {\n    width: auto;\n    color: white;\n    font-size: 45px;\n    margin: auto 15px;\n}\n\n", ""]);
// Exports
exports.locals = {
	"Backdrop": "src-components-UI-Backdrop-Backdrop-module__Backdrop--2cVmF",
	"Close": "src-components-UI-Backdrop-Backdrop-module__Close--8wpjp"
};
module.exports = exports;
