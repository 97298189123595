import * as actionTypes from "./actions";

const initialState = {
  user: null,
  tags: [],
  loginOpen: false,
  registrationOpen: false,
  sideDrawerOpen: false,
  postEditOpen: false,
  eventEditOpen: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_USER:
      return {
        ...state,
        user: action.user,
      };
    case actionTypes.SET_TAGS:
      return {
        ...state,
        tags: action.setTags,
      };
    case actionTypes.TOGGLE_LOGIN:
      return {
        ...state,
        loginOpen: !state.loginOpen,
      };
    case actionTypes.TOGGLE_REGISTRATION:
      return {
        ...state,
        registrationOpen: !state.registrationOpen,
      };
    case actionTypes.RESET_TAGS:
      return {
        ...state,
        tags: [],
      };
    case actionTypes.TOGGLE_DRAWER:
      return {
        ...state,
        sideDrawerOpen: !state.sideDrawerOpen,
      };
    case actionTypes.TOGGLE_EDIT:
      return {
        ...state,
        postEditOpen: !state.postEditOpen,
      };
    case actionTypes.TOGGLE_EVENT_EDIT:
      return {
        ...state,
        eventEditOpen: !state.eventEditOpen,
      };
    default:
      return {
        ...state,
      };
  }
};

export default reducer;
