// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-App-module__SocialIcons--yiIxq {\n}\n\n.src-App-module__Copyright--13Cqu {\n  text-align: center;\n}\n\n.src-App-module__FollowText--3vglZ {\n  font-size: 15px;\n  margin: 0;\n  padding: 0;\n  text-align: left;\n}\n\n.src-App-module__Facebook--37XSl,\n.src-App-module__Youtube--3wxSH,\n.src-App-module__Instagram--1_7n2,\n.src-App-module__Twitter--3VE5x {\n  color: #f4f4f4;\n  transition: color 0.2s;\n  padding-left: 20px !important;\n}\n\n.src-App-module__Facebook--37XSl {\n  padding-left: 0px !important;\n}\n\n.src-App-module__Facebook--37XSl:hover {\n  color: #00aced;\n}\n\n.src-App-module__Youtube--3wxSH:hover {\n  color: #f04b61;\n}\n\n.src-App-module__Instagram--1_7n2:hover {\n  color: #f36ad1;\n}\n\n.src-App-module__Twitter--3VE5x:hover {\n  color: #00aced;\n}\n\n.src-App-module__FooterCopyright--3NGay:hover a {\n  color: #6dd8ff;\n}\n\n.src-App-module__CopyrightText--2iDIG {\n  text-decoration: none;\n  color: #f4f4f4;\n}\n\n.src-App-module__TermsOfUse--Qzw8G {\n  text-decoration: none;\n  color: #f4f4f4;\n}\n.src-App-module__TermsOfUse--Qzw8G:hover {\n  color: #ad0a00;\n}\n\n@media (max-width: 768px) {\n  .src-App-module__CopyrightText--2iDIG {\n    font-size: 10px;\n  }\n  .src-App-module__Facebook--37XSl,\n  .src-App-module__Youtube--3wxSH,\n  .src-App-module__Instagram--1_7n2,\n  .src-App-module__Twitter--3VE5x {\n    font-size: 23px;\n    padding-left: 15px !important;\n  }\n\n  .src-App-module__Facebook--37XSl {\n    padding-left: 0px !important;\n  }\n\n  .src-App-module__SocialIcons--yiIxq {\n    left: 15px;\n  }\n\n  .src-App-module__Copyright--13Cqu {\n    padding-top: 25px;\n    right: 15px;\n  }\n\n  .src-App-module__FollowText--3vglZ {\n    font-size: 13px;\n  }\n}\n\n@media (max-width: 768px) {\n  .src-App-module__CopyrightText--2iDIG {\n    margin: 0.1rem;\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"SocialIcons": "src-App-module__SocialIcons--yiIxq",
	"Copyright": "src-App-module__Copyright--13Cqu",
	"FollowText": "src-App-module__FollowText--3vglZ",
	"Facebook": "src-App-module__Facebook--37XSl",
	"Youtube": "src-App-module__Youtube--3wxSH",
	"Instagram": "src-App-module__Instagram--1_7n2",
	"Twitter": "src-App-module__Twitter--3VE5x",
	"FooterCopyright": "src-App-module__FooterCopyright--3NGay",
	"CopyrightText": "src-App-module__CopyrightText--2iDIG",
	"TermsOfUse": "src-App-module__TermsOfUse--Qzw8G"
};
module.exports = exports;
