// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-containers-SearchFields-SearchFields-module__Container--28HwB {\n  width: 100%;\n  display: flex;\n  padding-top: 10px;\n  padding-bottom: 10px;\n  justify-content: center;\n  align-items: center;\n  position: fixed;\n  z-index: 382;\n  background-color: #f4f4f4;\n  top: 90px;\n  left: 0px;\n}\n\n.src-containers-SearchFields-SearchFields-module__Facebook--2cuRX {\n  color: #4f4746;\n  font-size: 35px !important;\n}\n\n.src-containers-SearchFields-SearchFields-module__Instagram--31YZe {\n  color: #4f4746;\n  font-size: 35px !important;\n}\n\n.src-containers-SearchFields-SearchFields-module__Youtube--3LDtC {\n  color: #4f4746;\n  font-size: 45px !important;\n}\n\n.src-containers-SearchFields-SearchFields-module__Facebook--2cuRX:hover {\n  cursor: pointer;\n}\n\n.src-containers-SearchFields-SearchFields-module__Instagram--31YZe:hover {\n  cursor: pointer;\n}\n\n.src-containers-SearchFields-SearchFields-module__Youtube--3LDtC:hover {\n  cursor: pointer;\n}\n\n@media(max-width: 1000px) {\n    .src-containers-SearchFields-SearchFields-module__Container--28HwB {\n        flex-direction: column;\n    }\n}\n", ""]);
// Exports
exports.locals = {
	"Container": "src-containers-SearchFields-SearchFields-module__Container--28HwB",
	"Facebook": "src-containers-SearchFields-SearchFields-module__Facebook--2cuRX",
	"Instagram": "src-containers-SearchFields-SearchFields-module__Instagram--31YZe",
	"Youtube": "src-containers-SearchFields-SearchFields-module__Youtube--3LDtC"
};
module.exports = exports;
