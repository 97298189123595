// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-components-UI-Input-Input-module__Input--2pgvu {\n    width: 100%;\n    padding: 10px;\n    box-sizing: border-box;\n}\n\n.src-components-UI-Input-Input-module__Label--8xPmK {\n    font-weight: bold;\n    display: inline-block;\n    margin-bottom: 8px;outline: none;\n}\n\n.src-components-UI-Input-Input-module__InputElement--38cKR {\n    outline: none;\n    border: none;\n    border-bottom: 2px solid #166873 ;\n    background-color: #f4f4f4;\n    font: inherit;\n    padding: 6px 10px;\n    display: block;\n    width: 100%;\n    box-sizing: border-box;\n    height: 51px;\n    /* outline: none; */\n}\n\n.src-components-UI-Input-Input-module__InputElement--38cKR:focus {\n    outline: none;\n    background-color:rgb(204, 204, 204, 0.1);\n}\n\n.src-components-UI-Input-Input-module__Invalid--3Cda3 {\n    border: 1px solid red;\n    background-color: #FDA49A;\n}\n\ntextarea {\n    resize: none;\n    height: 100px;\n}\n\n.src-components-UI-Input-Input-module__CheckBox--WEzeO {\n    padding: 10px;\n    margin: 0 0 10px;\n    display: inline-block;\n}\n\n.src-components-UI-Input-Input-module__CheckBox--WEzeO:hover {\n    cursor: pointer;\n}\n\n.src-components-UI-Input-Input-module__CheckBoxLabel--1NOt8 {\n    padding: 10px;\n    margin: 0 0 10px;\n    display: inline-block;\n}\n\n.src-components-UI-Input-Input-module__CheckBoxLabel--1NOt8:hover {\n    cursor: pointer;\n}\n", ""]);
// Exports
exports.locals = {
	"Input": "src-components-UI-Input-Input-module__Input--2pgvu",
	"Label": "src-components-UI-Input-Input-module__Label--8xPmK",
	"InputElement": "src-components-UI-Input-Input-module__InputElement--38cKR",
	"Invalid": "src-components-UI-Input-Input-module__Invalid--3Cda3",
	"CheckBox": "src-components-UI-Input-Input-module__CheckBox--WEzeO",
	"CheckBoxLabel": "src-components-UI-Input-Input-module__CheckBoxLabel--1NOt8"
};
module.exports = exports;
