import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import jwt from "jsonwebtoken";
import Avatar from "@material-ui/core/Avatar";

import classes from "./UserMenu.module.css";
import Button from "../../UI/Button/Button";

const getInitals = (name) => {
  if (!name) return;
  var initials = name.match(/\b\w/g) || [];
  initials = ((initials.shift() || "") + (initials.pop() || "")).toUpperCase();
  return initials;
};

class UserMenu extends Component {
  container = React.createRef();
  state = {
    open: false,
  };
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }
  handleClickOutside = (event) => {
    if (
      this.container.current &&
      !this.container.current.contains(event.target)
    ) {
      this.setState({
        open: false,
      });
    }
  };

  handleClickInside = () =>
    this.setState({
      ...this.state,
      open: false,
    });

  handleButtonClick = () => {
    this.setState((state) => {
      return {
        open: !state.open,
      };
    });
  };
  logoutUserHandler = () => {
    delete localStorage.jwtToken;
    const { history } = this.props;
    history.push("/");
    window.location.reload(true);
  };

  goToHandler = (path) => {
    const { history } = this.props;

    this.handleClickInside();
    history.push(path);
  };

  render() {
    let contentRendered;
    if (
      jwt.decode(localStorage.jwtToken).user.role === "super_admin" ||
      jwt.decode(localStorage.jwtToken).user.role === "admin"
    ) {
      contentRendered = (
        <li onClick={() => this.goToHandler("/admin-page/posts-statistics")}>
          Admin panel
        </li>
      );
    } else {
      contentRendered = null;
    }
    return (
      <div className={classes.App}>
        <div className={classes.Temp} ref={this.container}>
          <Button
            type="button"
            btnType={"UserMenuIcon"}
            clicked={this.handleButtonClick}
          >
            <div className={classes.Icon}>
              <Avatar
                className={classes.Avatar}
                style={{
                  display: "inline-flex",
                  backgroundColor: "black",
                  fontSize: "18px",
                  color: "white",
                  width: "20px",
                  height: "20px",
                  padding: "15px",
                  border: "2px solid #f4f4f4",
                }}
              >{`${getInitals(this.props.name)}`}</Avatar>
              <p
                className={classes.UserName}
                style={{
                  display: "inline-block",
                  fontSize: "1.2rem",
                  margin: "5px 5px",
                  color: "#f4f4f4",
                }}
              >
                {this.props.name}
              </p>
            </div>
          </Button>

          {this.state.open && (
            <div className={classes.Dropdown}>
              <ul className={classes.MenuItems}>
                {contentRendered}
                <li onClick={() => this.goToHandler("/settings")}>
                  Podešavanja
                </li>
                <li onClick={this.logoutUserHandler}>Odjavi se</li>
              </ul>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(UserMenu);
