// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-containers-Register-Register-module__Register--2Yfrn {\n  text-align: center;\n}\n\n.src-containers-Register-Register-module__Logo--3j9V_ {\n  text-align: center;\n  margin: 0 auto;\n  height: 100px;\n  display: block;\n  color: #ad0900;\n  margin-bottom: 10px;\n}\n", ""]);
// Exports
exports.locals = {
	"Register": "src-containers-Register-Register-module__Register--2Yfrn",
	"Logo": "src-containers-Register-Register-module__Logo--3j9V_"
};
module.exports = exports;
