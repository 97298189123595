import React from "react";
import { connect } from "react-redux";

import classes from "./Button.module.css";
import * as actionTypes from "../../../store/actions";

const button = props => (
  <button
    disabled={props.disabled}
    className={[classes.Button, classes[props.btnType]].join(" ")}
    onClick={() => {
      if (props.closeDrawer) {
        props.drawerToggleClickHandler();
      }
      props.clicked();
    }}
    onKeyPress={props.onKeyPress}
  >
    {props.children}
  </button>
);

const mapDispatchToProps = dispatch => {
  return {
    drawerToggleClickHandler: () =>
      dispatch({ type: actionTypes.TOGGLE_DRAWER })
  };
};

export default connect(null, mapDispatchToProps)(button);
