import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import cssClasses from "./App.module.css";
import Main from "./Main";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import YouTubeIcon from "@material-ui/icons/YouTube";

function Copyright() {
  return (
    <div
      style={{ fontSize: "1rem", color: "#f4f4f4" }}
      className={cssClasses.Copyright}
    >
      <p className={cssClasses.CopyrightText}>
        Copyright ©Media Biro 2021 | All rights reserved. |{" "}
        <a className={cssClasses.TermsOfUse} href="/termsofuse">
          Uslovi korišćenja
        </a>{" "}
        |{" "}
        <a className={cssClasses.TermsOfUse} href="/impressum">
          Impressum
        </a>
      </p>
      <a
        className={cssClasses.CopyrightText}
        // style={{ marginTop: "10px", padding: "0px", display: "block" }}
        href="https://under-it.com/"
      >
        {" "}
        Under-IT
      </a>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    minWidth: "100%",
  },
  footer: {
    // padding: theme.spacing(3, 2),
    // marginBottom: "0px",
    marginTop: "auto",
    height: "100px",
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.grey[200]
        : theme.palette.grey[800],
  },
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "inherit",
    "@media (max-width:410px)": {
      flexDirection: "column",
      justifyContent: "center",
    },
  },
}));

export default function StickyFooter() {
  const classes = useStyles();

  return (
    <div style={{ width: "100%" }} className={classes.root}>
      <Main />
      <CssBaseline />
      <footer className={classes.footer} style={{ zIndex: "401" }}>
        <Container className={classes.container} maxWidth="xl">
          <Copyright />
        </Container>
      </footer>
    </div>
  );
}
