import React from "react";
import ReactDOM from "react-dom";
import jwt from "jsonwebtoken";

import App from "./App";
// import registerServiceWorker from "./registerServiceWorker";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { createStore } from "redux";
import * as actionTypes from "./store/actions";
import "./index.css";

import reducer from "./store/reducer";

const store = createStore(reducer);

if (localStorage.jwtToken) {
  //console.log(jwt.decode(localStorage.jwtToken).user);
  store.dispatch({
    type: actionTypes.SET_USER,
    user: jwt.decode(localStorage.jwtToken).user
  });
}

const app = (
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);
ReactDOM.render(app, document.getElementById("root"));
// registerServiceWorker();
