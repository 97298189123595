// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-components-UI-Modal-Modal-module__Modal--Rgf_C {\n  position: fixed;\n  z-index: 500;\n  /* height: 450px; */\n  background-color: #f4f4f4;\n  width: 500px;\n  border: 1px solid #ccc;\n  box-shadow: 1px 1px 1px black;\n  /* padding: 16px; */\n  left: 15%;\n  top: 0%;\n  box-sizing: border-box;\n  transition: all 0.3s ease-out;\n  text-align: center;\n  /* width: 800px; */\n  left: calc(50% - 500px);\n  top: calc(50% - 450px);\n}\n\n@media (max-width: 600px) {\n  .src-components-UI-Modal-Modal-module__Modal--Rgf_C {\n    /* width: 500px; */\n    /* left: calc(50% - 250px); */\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"Modal": "src-components-UI-Modal-Modal-module__Modal--Rgf_C"
};
module.exports = exports;
